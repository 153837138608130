import { Dialog, DialogPanelProps, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'
import CloseIcon from '../icons/close-icon'

export enum ModalSizeEnum {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

export type ModalProps = {
  title: string
  opened: boolean
  onClose: () => void
  size?: ModalSizeEnum
  isFetching?: boolean
  afterLeave?: () => void
  afterEnter?: () => void
  className?: string
  contentClassName?: string
  isCentered?: boolean
} & DialogPanelProps<'div'>

function Modal({
  opened,
  onClose,
  children,
  title,
  size = ModalSizeEnum.small,
  isFetching,
  afterLeave,
  afterEnter,
  className,
  contentClassName,
  isCentered,
  ...rest
}: React.PropsWithChildren<ModalProps>) {
  return (
    <Transition.Root afterEnter={afterEnter} show={opened} as={Fragment}>
      <Dialog className="relative z-10" onClose={!isFetching ? onClose : () => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-darkblue/40 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            id={'modal-background'}
            className="flex min-h-full w-full  items-end justify-center lg:p-4 text-center sm:items-center sm:p-0"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={afterLeave}
            >
              <Dialog.Panel
                className={`relative w-full ${
                  size === ModalSizeEnum.small
                    ? 'max-w-2xl'
                    : size === ModalSizeEnum.medium
                    ? 'max-w-4xl'
                    : 'max-w-7xl'
                } transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 ${
                  className || ''
                }`}
                {...rest}
              >
                <div
                  className={`w-full p-6 lg:p-10 lg:pb-0 flex  items-start ${
                    isCentered ? 'justify-center' : 'justify-between'
                  }`}
                >
                  <h3 className={`${isCentered ? 'px-6 lg:px-10' : ''}`}>{title}</h3>
                  <button
                    className={`outline-none focus-visible:ring-2 ring-blue ring-offset-2 rounded-sm ${
                      isCentered && 'absolute top-6 lg:top-10 right-6 lg:right-10'
                    }`}
                    disabled={isFetching}
                    onClick={onClose}
                  >
                    <CloseIcon
                      className={`cursor-pointer ${isFetching && 'fill-gray pointer-events-none'}`}
                    />
                  </button>
                </div>
                <div className={twMerge('bg-white p-6 lg:p-10 rounded-lg', contentClassName)}>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default Modal
